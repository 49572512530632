import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'gps-tracker-textarea',
  standalone: false,
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss'
})
export class TextareaComponent {
  @Input() isRequired = false;
  @Input() classType = '';
  @Input() labelHide = false;
  @Input() labelValue = '';
  @Input() errorMessage = '';
  @Input() disabled = false;
  @Input() id = '';
  @Input() name = '';
  @Input() rows = '';
  @Input() placeholder = '';
  @Input() control: AbstractControl | undefined;
  @Input() inputType: 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' = 'primary';
}

