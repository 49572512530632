import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gps-tracker-checkbox',
  standalone: false,
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {

  @Input() id = '';
  @Input() label = '';
  @Input() isChecked: boolean = false;
  @Input() marginBottomZero: boolean = false;

  @Output() onInputChangeEvent: EventEmitter<any> = new EventEmitter();

  onInputChange() {
    this.onInputChangeEvent.emit();
  }

}
