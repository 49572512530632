import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckNullUndefinedService {

  checkNullAndUndefined(value: any) {
    if (value !== null && value !== undefined) {
      return true;
    }
    return false;
  }

  checkNullAndUndefinedWithBlank(value: any) {
    if (value !== null && value !== undefined && value !== "") {
      return true;
    }
    return false;
  }
}
