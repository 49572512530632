<div class="mb-2" [style]="mainElementStyle">
  @if (!labelHide && labelValue.length > 0) {
  <ng-container>
    <label class="form-label">
      {{labelValue}}
      @if(isRequired){
      <sup>*</sup>
      }
    </label>
  </ng-container>
  }
  <mat-select [ngClass]="{
          inputClassPrimary: inputType === 'primary',
          inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
          inputClassSecondary: inputType === 'secondary',
          inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
          inputClassWarning: inputType === 'warning',
          inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
          inputClassSuccess: inputType === 'success',
          inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',
          inputClassDisabled: disabled}" [placeholder]="placeholder" [id]="id" [style]="selectStyle"
    [formControl]="$any(control)" ngDefaultControl [value]="value" [attr.disabled]="disabled"
    (selectionChange)="onValueChange($event)">
    @if(data.length > 0){
    @for (item of data; track item;) {
    <mat-option [value]="item[valueFieldName]">{{item[labelFieldName]}}</mat-option>
    }
    }
    @else{
    <mat-option disabled="">No Data Found</mat-option>
    }
  </mat-select>
  @if (errorMessage.length > 0) {
  <ng-container>
    <label class="error-label">
      {{errorMessage}}
    </label>
  </ng-container>
  }
</div>