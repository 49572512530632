<div class="side-menu" [ngClass]="{'menuClose': !isMenuOpen}">
    <div class="app-sidebar-logo">
        <img src="../../../assets/images/app_logo.png" class='h-70px app-sidebar-logo-default' />
        <img src="../../../assets/images/app_mini_logo.png" class='h-70px app-sidebar-logo-minimize' />
        <button class="menu-toggle-btn" (click)="onHideShow()">
            <i class="fa-solid fa-arrow-left"></i>
        </button>
    </div>
    <div class="app-sidebar-wrapper">
        <div class="menu">
            @for (menuItem of menuData; track menuItem) {
            <div class="menu-item">
                <span class="menu-link" [ngClass]="{
                    'show': applicationMenuId === menuItem.application_menu_id,
                    'active': applicationMenuId === menuItem.application_menu_id && !(menuItem.screen_type === 'DETAIL' && menuItem.menu.length > 0)
                    }" (click)="menuClick(menuItem)">
                    <span class="menu-icon">
                        <mat-icon>{{menuItem.mat_icon}}</mat-icon>
                    </span>
                    <span class="menu-title">{{menuItem.menu_name}}</span>
                    @if(menuItem.screen_type === 'DETAIL' && menuItem.menu.length > 0){
                    <span class="menu-arrow">
                        <mat-icon>chevron_right</mat-icon>
                    </span>
                    }
                </span>
                @if(menuItem.screen_type === 'DETAIL' && menuItem.menu.length > 0){
                <div class="menu-sub" [ngClass]="{'show': applicationMenuId === menuItem.application_menu_id}">
                    <div class="menu-item">
                        @for (menu of menuItem.menu; track menu) {
                        <div class="menu-link without-sub"
                            [ngClass]="{'show': menu.application_sub_menu_id === applicationSubMenuId}"
                            (click)="openMenu(menu.application_sub_menu_id, menu.menu_url, false)">
                            <span class="menu-bullet">
                                <span class="bullet-dot"></span>
                            </span>
                            <span class="menu-title">{{menu.menu_name}}</span>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
</div>