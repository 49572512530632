import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'gps-tracker-select',
  standalone: false,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent {

  @Input() isRequired = false;
  @Input() placeholder = 'Select...';
  @Input() classType = '';
  @Input() labelHide = false;
  @Input() labelValue = '';
  @Input() errorMessage = '';
  @Input() disabled = false;
  @Input() id = '';
  @Input() name = '';
  @Input() labelFieldName: any;
  @Input() valueFieldName: any;
  @Input() data: any = [];
  @Input() control: AbstractControl | undefined | string;
  @Input() value: string = '';
  @Input() mainElementStyle: object = {};
  @Input() selectStyle: object = {};
  @Input() inputType: 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' = 'primary';

  @Output() change: EventEmitter<any> = new EventEmitter();

  onValueChange($event: any) {
    this.change.emit($event);
  }
}