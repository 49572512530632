import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services';
import { LocalStorage, Paths } from '../../enums';

@Component({
  selector: 'gps-tracker-top-nav',
  standalone: false,
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.scss'
})
export class TopNavComponent {

  @Input() isMenuOpen = true;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();

  constructor(private localStorageService: LocalStorageService, private router: Router) {
  }

  onHideShow(): void {
    this.menuToggle.emit(!this.isMenuOpen);
  }

  logout() {
    this.localStorageService.deleteData(LocalStorage.accessToken);
    this.localStorageService.deleteData(LocalStorage.refreshToken);
    this.localStorageService.deleteData(LocalStorage.userMenu);
    this.localStorageService.deleteData(LocalStorage.loginData);
    this.localStorageService.deleteData(LocalStorage.applicationMenuId);
    this.localStorageService.deleteData(LocalStorage.applicationSubMenuId);
    this.router.navigate(["/" + Paths.authentication]);
  }

}
