import { Component, Input } from '@angular/core';

@Component({
  selector: 'gps-tracker-card',
  standalone: false,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {

  @Input() title = '';
  @Input() subtitle = '';
  @Input() customCard = '';

}
