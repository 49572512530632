import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'gps-tracker-input',
  standalone: false,
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent {
  @Input() isRequired = false;
  @Input() classType = '';
  @Input() labelHide = false;
  @Input() labelValue = '';
  @Input() errorMessage = '';
  @Input() disabled = false;
  @Input() type = 'text';
  @Input() id = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() min = '';
  @Input() max = '';
  @Input() control: AbstractControl | undefined;
  @Input() checkBoxControl: boolean = false;
  @Input() checkBoxControlValue: boolean = false;
  @Input() checkBoxId = '';
  @Input() inputType: 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' = 'primary';

  @Output() onInputChangeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputElement', { static: true }) inputElement!: ElementRef;

  onInputChange() {
    this.onInputChangeEvent.emit();
  }

  getInputElement(): HTMLInputElement {
    return this.inputElement.nativeElement;
  }
}
