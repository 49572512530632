<mat-card class="gps-card" [ngClass]="customCard">
  @if (title || subtitle) {
  <mat-card-header>
    <div class="gps-card-title">
      @if (title) {
      <mat-card-title>{{ title }}</mat-card-title>
      }
      @if (subtitle) {
      <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
      }
    </div>
  </mat-card-header>
  }
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>