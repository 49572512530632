@if(innerWidth > 991.98){
<div class="webTable">
    <gps-tracker-card>
        @if(arrayValid){
        <div class="toolbarForTable">
            <div class="buttonAndLabel">
                <label class="form-label fs-6 fw-bold text-gray-900" [ngClass]="{'d-none': tableTitle.length === 0}">
                    {{tableTitle}}
                </label>
                <div appClickedOutside (clickedOutside)="clickedOutside()">
                    @if (columnsShow) {
                    <gps-tracker-button leftIcon="view_column" type="button" label="Columns" btnType="primary"
                        (clickEvent)='hideShowColumn()' />
                    }
                    @if(filterShow){
                    <gps-tracker-button leftIcon="filter_alt" type="button" label="Filters" btnType="primary"
                        (clickEvent)='hideShowFilter()' />
                    }
                    @if(sortShow){
                    <gps-tracker-button leftIcon="swap_vert" type="button" label="Sort" btnType="primary"
                        (clickEvent)='hideShowSort()' />
                    }
                    @if(exportShow){
                    <gps-tracker-button leftIcon="download" type="button" label="Export" btnType="primary"
                        (clickEvent)='download()' />
                    }

                    <span class="popup">
                        <span class="popuptext" [ngClass]="{'show': columnsPopupHideShow}">
                            <div class="popup-container">
                                @for (columnsOptionsItem of columnsOptions; track columnsOptionsItem;let i = $index) {
                                <gps-tracker-checkbox [id]="columnsOptionsItem.headerName"
                                    [label]="columnsOptionsItem.headerName" [isChecked]="columnsOptionsItem.display"
                                    (onInputChangeEvent)="columnsHideShow(i)" />
                                }
                            </div>
                            <button class="reset" [ngClass]="{'disabled': displayColumns.length === columns.length}"
                                [disabled]="displayColumns.length === columns.length" (click)="resetColumn()">
                                Reset
                            </button>
                        </span>
                        <span class="popuptext" [ngClass]="{'show': filterPopupHideShow}">
                            <div class="popup-container">
                                <form class="validate-form" [formGroup]="filterForm">
                                    <gps-tracker-select id="columns" name="columns" inputType="primary"
                                        [control]="filterFormControls.columns" labelValue="Columns"
                                        [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                        placeholder="Please select filter field" labelFieldName="label"
                                        valueFieldName="value" [data]="filterOptions" />

                                    <gps-tracker-select id="operator" name="operator" inputType="primary"
                                        [control]="filterFormControls.operator" labelValue="Operator"
                                        [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                        placeholder="Please select operator" labelFieldName="title"
                                        valueFieldName="value" [data]="operatorOptionsList" />

                                    <gps-tracker-input [control]="filterFormControls.filterValue" id="filterValue"
                                        name="filterValue" labelValue="Filter Value" placeholder="Search" />
                                </form>
                            </div>
                            <button class="reset" (click)="resetFilter()">
                                Reset
                            </button>
                        </span>
                        <span class="popuptext" [ngClass]="{'show': sortPopupHideShow}">
                            <div class="popup-container">
                                <form class="validate-form" [formGroup]="sortForm">
                                    <gps-tracker-select id="columns" name="columns" inputType="primary"
                                        [control]="sortFormControls.columns" labelValue="Columns"
                                        [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                        placeholder="Please select sorting field" labelFieldName="label"
                                        valueFieldName="value" [data]="sortByOptions" />

                                    <gps-tracker-select id="orderType" name="orderType" inputType="primary"
                                        [control]="sortFormControls.orderType" labelValue="Order Type"
                                        [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                        placeholder="Please select order type" labelFieldName="title"
                                        valueFieldName="value" [data]="sortOptionsList" />
                                </form>
                            </div>
                            <button class="reset" (click)="resetSort()">
                                Reset
                            </button>
                        </span>
                    </span>
                </div>
            </div>
            <div>
                <input type="search" name="search" placeholder="Search" class="form-control search search-control"
                    (input)="search($event)" />
            </div>
        </div>
        <div class="table-view">
            <table>
                <thead>
                    <tr>
                        @for (displayColumnsItem of displayColumns; track displayColumnsItem; let i = $index) {
                        <th (click)="updateSort(i)"
                            [ngStyle]="{'min-width': displayColumnsItem.headerName === 'Action' ? ((displayColumnsItem.list.length * 32) + 24) +'px': displayColumnsItem.headerName === 'Status' ? '94px' : 'auto','width': displayColumnsItem.headerName === 'Action' ? ((displayColumnsItem.list.length * 32) + 24) +'px': displayColumnsItem.headerName === 'Status' ? '94px' : 'auto'}">
                            <span class="head-view">
                                <span [ngClass]="{'header': displayColumnsItem.sortable}">
                                    {{displayColumnsItem.headerName}}
                                </span>
                                @if (displayColumnsItem.sortable && checkSort(displayColumnsItem.field, "Ascending")) {
                                <i class="fa-solid fa-arrow-up-wide-short"></i>
                                }
                                @else if(displayColumnsItem.sortable && checkSort(displayColumnsItem.field,
                                "Descending")){
                                <i class="fa-solid fa-arrow-down-short-wide"></i>
                                }
                                @else if(displayColumnsItem.sortable && checkNonSort(displayColumnsItem.field)){
                                <i class="fa-solid fa-arrow-up-wide-short showOnHover"></i>
                                }
                            </span>
                        </th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (currentRecordsItem of currentRecords; track currentRecordsItem;let index = $index) {
                    <tr>
                        @for (displayColumnsItem of displayColumns; track displayColumnsItem) {
                        <td>
                            @if (displayColumnsItem.field !== 'action' && displayColumnsItem.field !== 'status'){
                            @if (displayColumnsItem.type === 'radio'){
                            <gps-tracker-radio [data]="itemValueForRadio(currentRecordsItem, displayColumnsItem)"
                                [marginBottomZero]="true"
                                (onRadioChangeEvent)="onRadioChange(index, displayColumnsItem.field)" />
                            }
                            @else if (displayColumnsItem.type === 'checkbox'){
                            <gps-tracker-checkbox [id]="index.toString()" [marginBottomZero]="true"
                                [isChecked]="itemValueForCheckbox(currentRecordsItem, displayColumnsItem)"
                                (onInputChangeEvent)="onCheckBoxChange(index, displayColumnsItem.field)" />
                            }
                            @else if (displayColumnsItem.type === 'image'){
                            <img [src]="itemValue(currentRecordsItem, displayColumnsItem, index)"
                                (error)="errorHandler($event)">
                            }
                            @else if (displayColumnsItem.isTrueFalse){
                            <span
                                [ngClass]="{'active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 1,
                                            'de-active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 0}">
                                {{itemValue(currentRecordsItem, displayColumnsItem, index)}}
                            </span>
                            }
                            @else {
                            <span>{{itemValue(currentRecordsItem, displayColumnsItem, index)}}</span>
                            }
                            }
                            @else if (displayColumnsItem.field == 'status') {
                            <span
                                [ngClass]="{ 'active-status': currentRecordsItem[displayColumnsItem.field] === 1, 'de-active-status': currentRecordsItem[displayColumnsItem.field] === 0 }">
                                {{itemValue(currentRecordsItem, displayColumnsItem, index)}}
                            </span>
                            }
                            @else if (displayColumnsItem.field == 'action') {
                            <span>
                                @for (listItem of displayColumnsItem.list; track listItem) {
                                @if(listItem.conditionField === undefined ||
                                (
                                listItem.conditionField !== undefined &&
                                itemValueConditionCheck(currentRecordsItem,listItem)
                                )){
                                <i class="fa" [ngClass]="actionIcon(index, listItem.action)"
                                    (click)="action(index, listItem.action)"
                                    [title]="actionTitle(index, listItem.action, listItem.title)"></i>
                                }
                                }
                            </span>
                            }
                        </td>
                        }
                    </tr>
                    }
                    @if (currentRecords.length === 0){
                    <tr>
                        <td [attr.colspan]="displayColumns.length" class="no-data-found">
                            No Data Found
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        @if(paging){
        <div class="pagination">
            <div>
                Rows per page:
                <gps-tracker-select id="select" name="select" inputType="primary" [control]="pageSizeFormControl"
                    [mainElementStyle]="{margin: '0px !important'}"
                    [selectStyle]="{padding: '6px',marginLeft: '6px', height: 'auto'}"
                    placeholder="Please select page size" labelFieldName="title" valueFieldName="value"
                    [data]="pageSizeList" />
                <span class="details">
                    {{startFrom}} - {{endTo}} of {{totalRecord}}
                </span>
            </div>

            @if (!(currentPage == totalPages && currentPage == 1)){
            <div>
                <span class="prevNext" (click)="onPageChange(1, 'prev')">
                    <i class="fa-solid fa-chevron-left" [ngClass]="{'disabled': currentPage === 1}"></i>
                </span>
                <span class="prevNext" (click)="onPageChange(1, 'next')">
                    <i class="fa-solid fa-chevron-right" [ngClass]="{'disabled': currentPage === totalPages}"></i>
                </span>
            </div>
            }
        </div>
        }
        }
        @else {
        <div class="no-data-found">Radio and checkbox column needed unique column name</div>
        }

    </gps-tracker-card>
</div>
}
@else {
<div class="mobileTable">
    @if(arrayValid){
    <div class="toolbarForTable">
        <div class="buttonAndLabel">
            <label class="form-label fs-6 fw-bold text-gray-900" [ngClass]="{'hide': tableTitle.length === 0}">
                {{tableTitle}}
            </label>
            <div appClickedOutside (clickedOutside)="clickedOutside()">
                @if (columnsShow) {
                <gps-tracker-button leftIcon="view_column" type="button" label="Columns" btnType="primary"
                    (clickEvent)='hideShowColumn()' />
                }
                @if(filterShow){
                <gps-tracker-button leftIcon="filter_alt" type="button" label="Filters" btnType="primary"
                    (clickEvent)='hideShowFilter()' />
                }
                @if(sortShow){
                <gps-tracker-button leftIcon="swap_vert" type="button" label="Sort" btnType="primary"
                    (clickEvent)='hideShowSort()' />
                }
                @if(exportShow){
                <gps-tracker-button leftIcon="download" type="button" label="Export" btnType="primary"
                    (clickEvent)='download()' />
                }
                <span class="popup">
                    <span class="popuptext" [ngClass]="{'show': columnsPopupHideShow}">
                        <div class="popup-container">
                            @for (columnsOptionsItem of columnsOptions; track columnsOptionsItem; let i = $index) {
                            <gps-tracker-checkbox [id]="columnsOptionsItem.headerName"
                                [label]="columnsOptionsItem.headerName" [isChecked]="columnsOptionsItem.display"
                                (onInputChangeEvent)="columnsHideShow(i)" />
                            }
                        </div>
                        <button class="reset" [ngClass]="{'disabled': displayColumns.length === columns.length}"
                            [disabled]="displayColumns.length === columns.length" (click)="resetColumn()">
                            Reset
                        </button>
                    </span>
                    <span class="popuptext" [ngClass]="{'show': filterPopupHideShow}">
                        <div class="popup-container">
                            <form class="validate-form" [formGroup]="filterForm">
                                <gps-tracker-select id="columns" name="columns" inputType="primary"
                                    [control]="filterFormControls.columns" labelValue="Columns"
                                    [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                    placeholder="Please select filter field" labelFieldName="label"
                                    valueFieldName="value" [data]="filterOptions" />

                                <gps-tracker-select id="operator" name="operator" inputType="primary"
                                    [control]="filterFormControls.operator" labelValue="Operator"
                                    [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                    placeholder="Please select operator" labelFieldName="title" valueFieldName="value"
                                    [data]="operatorOptionsList" />

                                <gps-tracker-input [control]="filterFormControls.filterValue" id="filterValue"
                                    name="filterValue" labelValue="Filter Value" placeholder="Search" />
                            </form>
                        </div>
                        <button class="reset" (click)="resetFilter()">
                            Reset
                        </button>
                    </span>
                    <span class="popuptext" [ngClass]="{'show': sortPopupHideShow}">
                        <div class="popup-container">
                            <form class="validate-form" [formGroup]="sortForm">
                                <gps-tracker-select id="columns" name="columns" inputType="primary"
                                    [control]="sortFormControls.columns" labelValue="Columns"
                                    [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                    placeholder="Please select sorting field" labelFieldName="label"
                                    valueFieldName="value" [data]="sortByOptions" />

                                <gps-tracker-select id="orderType" name="orderType" inputType="primary"
                                    [control]="sortFormControls.orderType" labelValue="Order Type"
                                    [mainElementStyle]="{'margin-bottom': '0.5rem !important'}"
                                    placeholder="Please select order type" labelFieldName="title" valueFieldName="value"
                                    [data]="sortOptionsList" />
                            </form>
                        </div>
                        <button class="reset" (click)="resetSort()">
                            Reset
                        </button>
                    </span>
                </span>
            </div>
        </div>
        <div>
            <input type="search" name="search" placeholder="Search" class="form-control search search-control"
                (input)="search($event)" />
        </div>
    </div>
    <div class="custom-table container-fluid">
        @if (currentRecords.length > 0){
        <div class="row">
            @for (currentRecordsItem of currentRecords; track currentRecordsItem;let index = $index) {
            <div class="zIndexZero" [ngClass]="responsiveClass">
                <gps-tracker-card [customCard]="'information-card'">
                    @for (displayColumnsItem of displayColumns; track displayColumnsItem;let itemIndex = $index) {
                    <span>
                        @if (displayColumnsItem.field !== 'action' && displayColumnsItem.field !== 'status'){
                        <div class="{{displayColumnsItem.mobileClass}} {{displayColumnsItem.field}}">
                            <b class="field-title">{{displayColumnsItem.mobileHeaderName ||
                                displayColumnsItem.headerName}}</b>
                            @if (displayColumnsItem.type === 'radio'){
                            <gps-tracker-radio [data]="itemValueForRadio(currentRecordsItem, displayColumnsItem)"
                                [marginBottomZero]="true"
                                (onRadioChangeEvent)="onRadioChange(index, displayColumnsItem.field)" />
                            }
                            @else if (displayColumnsItem.type === 'checkbox'){
                            <gps-tracker-checkbox [id]="index.toString()"
                                [isChecked]="itemValueForCheckbox(currentRecordsItem, displayColumnsItem)"
                                [marginBottomZero]="true"
                                (onInputChangeEvent)="onCheckBoxChange(index, displayColumnsItem.field)" />
                            }
                            @else if (displayColumnsItem.type === 'image'){
                            <img [src]="itemValue(currentRecordsItem, displayColumnsItem, index)"
                                (error)="errorHandler($event)">
                            }
                            @else if (displayColumnsItem.isTrueFalse){
                            <span
                                [ngClass]="{'active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 1,
                                            'de-active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 0}">
                                {{itemValue(currentRecordsItem, displayColumnsItem, index)}}
                            </span>
                            }
                            @else {
                            {{itemValue(currentRecordsItem, displayColumnsItem, index)}}
                            }
                        </div>
                        }
                        @else if (displayColumnsItem.field === 'status') {
                        <div class="{{displayColumnsItem.mobileClass}} {{displayColumnsItem.field}}">
                            <b class="field-title">{{displayColumnsItem.mobileHeaderName ||
                                displayColumnsItem.headerName}}</b>
                            <span
                                [ngClass]="{'active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 1,
                                            'de-active-status-mobile': currentRecordsItem[displayColumnsItem.field] === 0}">
                                {{itemValue(currentRecordsItem, displayColumnsItem, index)}}
                            </span>
                        </div>
                        }
                    </span>
                    }
                </gps-tracker-card>
                @for (displayColumnsItem of displayColumns; track displayColumnsItem; let itemIndex = $index) {
                <span>
                    @if (displayColumnsItem.field === 'action'){
                    <gps-tracker-card [customCard]="'action-card'">
                        @for (listItem of displayColumnsItem.list; track listItem) {
                        <i class="fa" [ngClass]="actionIcon(index, listItem.action)"
                            (click)="action(index, listItem.action)"
                            [title]="actionTitle(index, listItem.action, listItem.title)"></i>
                        }
                    </gps-tracker-card>
                    }
                </span>
                }
            </div>
            }
        </div>
        }
        @else {
        <div class="no-data-found">No Data Found</div>
        }
    </div>

    @if(paging){
    <div class="pagination">
        <div>
            Rows per page:
            <gps-tracker-select id="select" name="select" inputType="primary" [control]="pageSizeFormControl"
                [mainElementStyle]="{margin: '0px !important'}" labelFieldName="title" valueFieldName="value"
                [selectStyle]="{padding: '6px',marginLeft: '6px', height: 'auto'}" [data]="pageSizeList" />
            <span class="details">
                {{startFrom}} - {{endTo}} of {{totalRecord}}
            </span>
        </div>
        @if (!(currentPage == totalPages && currentPage == 1)){
        <div>
            <span class="prevNext" (click)="onPageChange(1, 'prev')">
                <i class="fa-solid fa-chevron-left" [ngClass]="{'disabled': currentPage === 1}"></i>
            </span>
            <span class="prevNext" (click)="onPageChange(1, 'next')">
                <i class="fa-solid fa-chevron-right" [ngClass]="{'disabled': currentPage === totalPages}"></i>
            </span>
        </div>
        }
    </div>
    }
    }
    @else {
    <div class="no-data-found">Radio and checkbox column needed unique column name</div>
    }
</div>
}


<swal #deleteSwal title="Are you sure you want to delete?" iconHtml="<i class='fa-regular fa-trash-can'></i>"
    confirmButtonText="Yes" cancelButtonText="No" [showCancelButton]="true" [focusCancel]="true"
    (confirm)="confirmDelete()">
</swal>