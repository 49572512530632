import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CheckNullUndefinedService } from './check-null-undefined.service';

@Injectable({
  providedIn: 'root'
})
export class DateTransformService {
  datePipe = new DatePipe("en-US");
  data: any;
  constructor(private checkNullUndefinedService: CheckNullUndefinedService) { }

  transFormDate(value: any) {
    if (this.checkNullUndefinedService.checkNullAndUndefinedWithBlank(value)) {
      this.data = this.datePipe.transform(value, 'dd-MM-yyyy hh:mm:ss a', 'GMT');
      return this.data;
    }
    return "";
  }

  transFormDateWithoutTimeZone(value: any) {
    if (this.checkNullUndefinedService.checkNullAndUndefinedWithBlank(value)) {
      this.data = this.datePipe.transform(value, 'dd-MM-yyyy');
      return this.data;
    }
    return "";
  }

  transFormDateOnly(value: any) {
    if (this.checkNullUndefinedService.checkNullAndUndefinedWithBlank(value)) {
      this.data = this.datePipe.transform(value, 'yyyy-MM-dd', 'GMT');
      return this.data;
    }
    return "";
  }

  transFormTimeOnly(value: any) {
    if (this.checkNullUndefinedService.checkNullAndUndefinedWithBlank(value)) {
      this.data = this.datePipe.transform(value, 'HH:mm', 'GMT');
      return this.data;
    }
    return "";
  }
}
