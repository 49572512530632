import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CardComponent } from './card/card.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { DemoSideNavComponent } from './demo-side-nav/demo-side-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TableComponent } from './table/table.component';
import { FilterOrderByDialogComponent } from './table/filter-order-by-dialog/filter-order-by-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopNavComponent } from './top-nav/top-nav.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { RadioComponent } from './radio/radio.component';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClickedOutsideDirective } from '../directive/clicked-outside.directive';
import { TextareaComponent } from './textarea/textarea.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    ClickedOutsideDirective,
    ButtonComponent,
    CheckboxComponent,
    CardComponent,
    InputComponent,
    PageTitleComponent,
    RadioComponent,
    SelectComponent,
    DemoSideNavComponent,
    SideNavComponent,
    TopNavComponent,
    TableComponent,
    FilterOrderByDialogComponent,
    TextareaComponent,
    MultiSelectComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    CardComponent,
    InputComponent,
    PageTitleComponent,
    RadioComponent,
    SelectComponent,
    DemoSideNavComponent,
    SideNavComponent,
    TopNavComponent,
    TableComponent,
    FilterOrderByDialogComponent,
    TextareaComponent,
    MultiSelectComponent,
    MaterialModule,
    ConfirmationDialogComponent
  ]
})
export class SharedModule { }
