import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from './';
import { Observable, Subject } from 'rxjs';
import { UserApplicationModuleDetailsData } from '../../service-proxies/models/user-application-module-details-data.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  apiCall = new Subject<any>();
  applicationModuleDataCall = new Subject<any>();

  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  userLogout() {
    this.localStorageService.deleteAllData();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }

  setApiCall(flag: any) {
    this.apiCall.next(flag);
  }

  getApiCall(): Observable<any> {
    return this.apiCall.asObservable();
  }

  setApplicationModule(applicationModuleData: UserApplicationModuleDetailsData[]) {
    this.applicationModuleDataCall.next(applicationModuleData);
  }

  getApplicationModule(): Observable<any> {
    return this.applicationModuleDataCall.asObservable();
  }

}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}