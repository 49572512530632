<div class="confirmationDialog">
  <div class="title">{{data.title || 'Confirmation'}}</div>
  <div class="content">
    {{data.message || 'Are you Sure?'}}
  </div>
  <div class="footer">
    <gps-tracker-button type="button" [label]="data.yesButtonLabel || 'Yes'" btnType="primary"
      (click)="dialogRef.close(1);" />
    <gps-tracker-button (clickEvent)='dialogRef.close(0);' type="button" [label]="data.noButtonLabel || 'NO'"
      btnType="secondary" />
  </div>
</div>