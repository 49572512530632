<div class="app-header app-container container-fluid d-flex align-items-stretch justify-content-between">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu" (click)="onHideShow()">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px">
                <i class="fa-solid fa-bars fs-3"></i>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
        <div class="app-navbar-item ms-1 ms-md-4">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px cursor-pointer">
                <i class="fa-solid fa-bell fs-3"></i>
                <span
                    class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
            </div>
        </div>
        <div class="app-navbar-item ms-1 ms-md-4">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px cursor-pointer"
                (click)="logout()">
                <i class="fa-solid fa-right-from-bracket fs-3"></i>
            </div>
        </div>
    </div>
</div>