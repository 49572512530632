import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

 verticalPosition: MatSnackBarVerticalPosition='top'; 
 horizontalPosition:MatSnackBarHorizontalPosition = 'right';

  constructor(private messageService: MatSnackBar) { 
    switch (Capacitor.getPlatform()) {
      case 'ios':
      case 'android':
        this.verticalPosition = 'bottom';
        this.horizontalPosition = 'center';
        break;
    }
  }

  toastSuccess(message: string) {
    this.messageService.open(message, '', {
      duration: 3000,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['success-snackbar'],
    });
  }

  toastError(message: string) {
    this.messageService.open(message, '', {
      duration: 3000,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['error-snackbar'],
    });
  }

  toastInfo(message: string) {
    this.messageService.open(message, '', {
      duration: 3000,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['info-snackbar'],
    });
  }

  toastWarning(message: string) {
    this.messageService.open(message, '', {
      duration: 3000,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['warning-snackbar'],
    });
  }

  showApiValidationError(response: any) {
    if (response.status === 0) {
      this.toastWarning(response.error || response.message || '');
    } else {
      this.toastError(response.error || response.message || '');
    }
  }
}
