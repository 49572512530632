import { Component, Input } from '@angular/core';

@Component({
  selector: 'gps-tracker-page-title',
  standalone: false,
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss'
})
export class PageTitleComponent {

  @Input() pageTitle = "";
  @Input() pageBreadcrumb: any = [];

}

