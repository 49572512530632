<div class="mb-2">
    @if (!labelHide && labelValue.length > 0) {
    <ng-container>
        <label class="form-label">
            {{labelValue}}
            @if(isRequired){
            <sup>*</sup>
            }
        </label>
    </ng-container>
    }
    <textarea [ngClass]="{
                inputClassPrimary: inputType === 'primary',
                inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
                inputClassSecondary: inputType === 'secondary',
                inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
                inputClassWarning: inputType === 'warning',
                inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
                inputClassSuccess: inputType === 'success',
                inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',                
                inputClassError: inputType === 'error',
                inputClassErrorWithoutBorder: inputType === 'errorWithoutBorder',
                inputClassDisabled: disabled}" [formControl]="$any(control)" ngDefaultControl [id]="id" [name]="name"
        [rows]="rows" [attr.disabled]="disabled" [placeholder]="placeholder"></textarea>
    @if (errorMessage.length > 0) {
    <ng-container>
        <label class="error-label">
            {{errorMessage}}
        </label>
    </ng-container>
    }
</div>